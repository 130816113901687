
export const getVersion = function() { 
    var MAJOR=3;
    var MINOR=1;
    var RELEASE=0;
    var COMMIT="a8a79d38";
    var BUILD="456";
    return MAJOR + "." + MINOR + "." + RELEASE + "." + BUILD + "-" + COMMIT; 
}

export default getVersion;
